define('ember-cp-validations/validators/inline', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    /**
     * Override the validator's `validate` method with the one that was
     * passed in via the options.
     *
     * @method buildOptions
     * @param  {Object}     options
     * @param  {Object}     defaultOptions
     * @param  {Object}     globalOptions
     * @return {Object}
     */
    buildOptions(options = {}, ...args) {
      (true && !(options && typeof options.validate === 'function') && Ember.assert(`[validator:inline] You must pass in a validate function`, options && typeof options.validate === 'function'));


      const opts = Ember.assign({}, options);

      this.validate = opts.validate;
      delete opts.validate;

      return this._super(opts, ...args);
    }
  });
});