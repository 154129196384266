define('ember-awesome-macros/array/-utils', ['exports', 'ember-macro-helpers/lazy-computed', 'ember-macro-helpers/normalize-array-key', 'ember-macro-helpers/create-class-computed'], function (exports, _lazyComputed, _normalizeArrayKey, _createClassComputed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.normalizeArray = normalizeArray;
  exports.normalizeArray2 = normalizeArray2;
  exports.normalizeArray3 = normalizeArray3;


  const sentinelValue = {};

  function normalizeArrayArgs(keys) {
    keys[0] = (0, _normalizeArrayKey.default)(keys[0]);
  }

  function getDefaultValue(func, identityVal) {
    let val = func();
    return val === sentinelValue ? identityVal : val;
  }

  function normalizeArray({
    defaultValue = () => sentinelValue
  }, callback) {
    return (...keys) => {
      normalizeArrayArgs(keys);

      return (0, _lazyComputed.default)(...keys, function (get, arrayKey, ...args) {
        let arrayVal = get(arrayKey);
        if (!arrayVal) {
          return getDefaultValue(defaultValue, arrayVal);
        }

        let values = args.map(get);
        return callback.call(this, arrayVal, ...values);
      });
    };
  }

  function normalizeArray2(funcStr, defaultValue = () => sentinelValue) {
    return (...keys) => {
      normalizeArrayArgs(keys);

      return (0, _lazyComputed.default)(...keys, (get, arrayKey, ...args) => {
        let arrayVal = get(arrayKey);
        let isArrayProxy = arrayVal instanceof Ember.ArrayProxy;
        if (!Array.isArray(arrayVal) && !isArrayProxy) {
          return getDefaultValue(defaultValue, arrayVal);
        }

        let emberArrayVal;
        if (isArrayProxy) {
          emberArrayVal = arrayVal;
        } else {
          emberArrayVal = Ember.A(arrayVal);
        }

        let prop = emberArrayVal[funcStr];
        if (typeof prop === 'function') {
          return prop.apply(emberArrayVal, args.map(get));
        }

        return prop;
      });
    };
  }

  function normalizeArray3({
    firstDefault = () => sentinelValue,
    secondDefault = () => sentinelValue,
    func
  }) {
    return (0, _createClassComputed.default)([false, true], (array, key, ...args) => {
      return (0, _lazyComputed.default)((0, _normalizeArrayKey.default)(array, [key]), ...args, function (get, arrayKey, ...args) {
        let arrayVal = get(arrayKey);
        let isArrayProxy = arrayVal instanceof Ember.ArrayProxy;
        if (!Array.isArray(arrayVal) && !isArrayProxy) {
          return getDefaultValue(firstDefault, arrayVal);
        }
        if (typeof key !== 'string') {
          return getDefaultValue(secondDefault, arrayVal);
        }

        let emberArrayVal;
        if (isArrayProxy) {
          emberArrayVal = arrayVal;
        } else {
          emberArrayVal = Ember.A(arrayVal);
        }

        let resolvedArgs = [key, ...args.map(get)];
        if (typeof func === 'function') {
          return func(emberArrayVal, ...resolvedArgs);
        }

        return emberArrayVal[func](...resolvedArgs);
      });
    });
  }
});