define('ember-shepherd/services/tour', ['exports', 'ember-shepherd/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {
    // Configuration Options
    confirmCancel: false,
    confirmCancelMessage: null,
    defaults: {},
    disableScroll: false,
    errorTitle: null,
    isActive: false,
    messageForUser: null,
    modal: false,
    modalContainer: 'body',
    requiredElements: [],
    steps: [],

    willDestroy() {
      (0, _utils.cleanupShepherdElements)();
      this.cleanup();
    },

    /**
     * Get the tour object and call back
     * @public
     */
    back() {
      Ember.get(this, 'tourObject').back();
      this.trigger('back');
    },

    /**
     * Cancel the tour
     * @public
     */
    cancel() {
      Ember.get(this, 'tourObject').cancel();
    },

    /**
     * Advance the tour to the next step and trigger next
     * @public
     */
    next() {
      Ember.get(this, 'tourObject').next();
      this.trigger('next');
    },

    /**
     * Show a specific step, by passing its id
     * @param id The id of the step you want to show
     * @public
     */
    show(id) {
      Ember.get(this, 'tourObject').show(id);
    },

    start() {
      Ember.set(this, 'isActive', true);
      Ember.get(this, 'tourObject').start();
    },

    onTourStart() {
      if (Ember.get(this, 'modal')) {
        const shepherdOverlay = document.createElement('div');
        shepherdOverlay.id = 'shepherdOverlay';
        const parent = document.querySelector(Ember.get(this, 'modalContainer'));
        parent.appendChild(shepherdOverlay);
      }
      if (Ember.get(this, 'disableScroll')) {
        disableScroll.on(window);
      }
      this.trigger('start');
    },

    /**
     * This function is called when a tour is completed or cancelled to initiate cleanup.
     * @param {string} completeOrCancel 'complete' or 'cancel'
     */
    onTourFinish(completeOrCancel) {
      Ember.set(this, 'isActive', false);
      this.cleanup();
      this.trigger(completeOrCancel);
    },

    /**
     * Cleanup the modal leftovers, like the overlay and highlight, so they don't hang around.
     * @private
     */
    cleanup() {
      if (Ember.get(this, 'disableScroll')) {
        disableScroll.off(window);
      }
      if (Ember.get(this, 'modal')) {
        const tour = Ember.get(this, 'tourObject');

        if (tour) {
          const { steps } = tour;

          steps.map(step => {
            const stepElement = (0, _utils.getElementForStep)(step);

            if (step && step.options.attachTo && stepElement) {
              stepElement.style.pointerEvents = 'auto';
            }
          });
        }
        Ember.run('afterRender', () => {
          (0, _utils.removeElement)('#shepherdOverlay');
          (0, _utils.removeElement)('#highlightOverlay');

          const shepherdModal = document.querySelector('.shepherd-modal');

          if (shepherdModal) {
            shepherdModal.classList.remove('shepherd-modal');
          }
        });
      }
    },

    /**
     * Creates an overlay element clone of the element you want to highlight and copies all the styles.
     * @param step The step object that points to the element to highlight
     * @private
     */
    createHighlightOverlay(step) {
      (0, _utils.removeElement)('#highlightOverlay');

      const currentElement = (0, _utils.getElementForStep)(step);

      if (currentElement) {
        const highlightElement = currentElement.cloneNode(true);

        highlightElement.setAttribute('id', 'highlightOverlay');
        document.body.appendChild(highlightElement);

        this.setComputedStylesOnClonedElement(currentElement, highlightElement);

        // Style all internal elements as well
        const { children } = currentElement;

        const clonedChildren = highlightElement.children;

        for (let i = 0; i < children.length; i++) {
          this.setComputedStylesOnClonedElement(children[i], clonedChildren[i]);
        }

        (0, _utils.setPositionForHighlightElement)({
          currentElement,
          highlightElement
        });

        window.addEventListener('resize', () => {
          Ember.run.debounce(this, _utils.setPositionForHighlightElement, {
            currentElement,
            highlightElement
          }, 50);
        });
      }
    },

    /**
     * Set computed styles on the cloned element
     *
     * @method setComputedStylesOnClonedElement
     * @param element element we want to copy
     * @param clonedElement cloned element above the overlay
     * @private
     */
    setComputedStylesOnClonedElement(element, clonedElement) {
      const computedStyle = window.getComputedStyle(element, null);

      for (let i = 0; i < computedStyle.length; i++) {
        const propertyName = computedStyle[i];

        clonedElement.style[propertyName] = computedStyle.getPropertyValue(propertyName);
      }
    },

    /**
     * This wraps the cancel function in a confirm dialog
     * @param  {boolean} confirmCancel Whether to show the dialog or not
     * @param  {string} confirmCancelMessage The message to display
     * @param  {object} tourObject The tour object
     * @private
     */
    wrapCancelFunction(confirmCancel, confirmCancelMessage, tourObject) {
      const cancelFunction = tourObject.cancel;

      if (confirmCancel) {
        const cancelMessage = confirmCancelMessage || 'Are you sure you want to stop the tour?';

        const newCancelFunction = () => {
          const stopTour = window.confirm(cancelMessage);
          if (stopTour) {
            cancelFunction();
          }
        };
        tourObject.cancel = newCancelFunction;
      }
    },

    initialize() {
      const defaults = Ember.get(this, 'defaults');

      const tourObject = new Shepherd.Tour({
        defaults
      });

      // Allow for confirm cancel dialog
      this.wrapCancelFunction(Ember.get(this, 'confirmCancel'), Ember.get(this, 'confirmCancelMessage'), tourObject);

      tourObject.on('start', Ember.run.bind(this, 'onTourStart'));
      tourObject.on('complete', Ember.run.bind(this, 'onTourFinish', 'complete'));
      tourObject.on('cancel', Ember.run.bind(this, 'onTourFinish', 'cancel'));
      Ember.set(this, 'tourObject', tourObject);
    },

    /**
     * Creates a button of the specified type, with the given classes and text
     *
     * @param type The type of button cancel, back, or next
     * @param classes Classes to apply to the button
     * @param text The text for the button
     * @param action The action to call
     * @returns {{action: *, classes: *, text: *}}
     * @private
     */
    makeButton({ type, classes, text, action }) {
      const builtInButtonTypes = ['back', 'cancel', 'next'];
      if (builtInButtonTypes.includes(type)) {
        action = Ember.run.bind(this, function () {
          this[type]();
        });
      } else {
        action = action || function () {};
      }
      return {
        action,
        classes,
        text
      };
    },

    /**
     * Check if attachTo is an object, if it is, put element and on into a string,
     * if it is already a string, just return that string
     *
     * @param attachTo
     * @returns {*}
     * @private
     */
    normalizeAttachTo(attachTo) {
      if (attachTo && typeof attachTo.element === 'string' && typeof attachTo.on === 'string') {
        return `${attachTo.element} ${attachTo.on}`;
      } else {
        return attachTo;
      }
    },

    /**
     * Modulates the styles of the passed step's target element, based on the step's options and
     * the tour's `modal` option, to visually emphasize the element
     *
     * @param step The step object that attaches to the element
     * @private
     */
    popoutElement(step) {
      const currentElement = (0, _utils.getElementForStep)(step);

      if (!currentElement) {
        return;
      }

      if (step.options.highlightClass) {
        currentElement.classList.add(step.options.highlightClass);
      }

      if (Ember.get(this, 'modal')) {
        currentElement.style.pointerEvents = 'none';

        if (step.options.copyStyles) {
          this.createHighlightOverlay(step);
        } else {
          (0, _utils.toggleShepherdModalClass)(currentElement);
        }
      }
    },

    /**
     * Observes the array of requiredElements, which are the elements that must be present at the start of the tour,
     * and determines if they exist, and are visible, if either is false, it will stop the tour from executing.
     * @private
     */
    requiredElementsPresent() {
      let allElementsPresent = true;

      const requiredElements = Ember.get(this, 'requiredElements');

      if (Ember.isPresent(requiredElements)) {
        /* istanbul ignore next: also can't test this due to things attached to root blowing up tests */
        requiredElements.forEach(element => {
          const selectedElement = document.querySelector(element.selector);

          if (allElementsPresent && (!selectedElement || (0, _utils.elementIsHidden)(selectedElement))) {
            allElementsPresent = false;
            Ember.set(this, 'errorTitle', element.title);
            Ember.set(this, 'messageForUser', element.message);
          }
        });
      }
      return allElementsPresent;
    },

    // TODO: Figure out how to use a computed instead of an observer here
    /**
     * Create a tour object based on the current configuration
     * @private
     */
    stepsChange: Ember.observer('steps', function () {
      this.initialize();
      const steps = Ember.get(this, 'steps');

      const tour = Ember.get(this, 'tourObject');

      // Return nothing if there are no steps
      if (Ember.isEmpty(steps)) {
        return;
      }
      /* istanbul ignore next: also can't test this due to things attached to root blowing up tests */
      if (!this.requiredElementsPresent()) {
        tour.addStep('error', {
          buttons: [{
            text: 'Exit',
            action: tour.cancel
          }],
          classes: 'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',
          copyStyles: false,
          title: Ember.get(this, 'errorTitle'),
          text: [Ember.get(this, 'messageForUser')]
        });
        return;
      }

      steps.forEach((step, index) => {
        const { id, options } = step;

        options.buttons = options.builtInButtons.map(this.makeButton, this);
        options.attachTo = this.normalizeAttachTo(options.attachTo);
        tour.addStep(id, options);

        // Step up events for the current step
        const currentStep = tour.steps[index];

        currentStep.on('before-show', () => {
          this.popoutElement(currentStep);
        });
        currentStep.on('hide', () => {
          // Remove element copy, if it was cloned
          const currentElement = (0, _utils.getElementForStep)(currentStep);

          if (currentElement) {
            if (currentStep.options.highlightClass) {
              currentElement.classList.remove(currentStep.options.highlightClass);
            }

            (0, _utils.removeElement)('#highlightOverlay');
          }
        });

        if (!currentStep.options.scrollToHandler) {
          currentStep.options.scrollToHandler = elem => {
            // Allow scrolling so scrollTo works.
            disableScroll.off(window);

            if (typeof elem !== 'undefined') {
              elem.scrollIntoView();
            }

            Ember.run.later(() => {
              if (Ember.get(this, 'disableScroll')) {
                disableScroll.on(window);
              }
            }, 50);
          };
        }
      });
    })
  });
});