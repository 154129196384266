define('ember-awesome-macros/tag', ['exports', 'ember-macro-helpers/curried-computed'], function (exports, _curriedComputed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (strings, ...values) {
    return (0, _curriedComputed.default)((...values) => {
      return strings.reduce((total, cur, i) => {
        return total + values[i - 1] + cur;
      });
    })(...values);
  };
});