define('ember-awesome-macros/equal', ['exports', 'ember-macro-helpers/lazy-curried-computed'], function (exports, _lazyCurriedComputed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _lazyCurriedComputed.default)((get, firstKey, ...keys) => {
    let firstVal = get(firstKey);
    for (let i = 0; i < keys.length; i++) {
      if (firstVal !== get(keys[i])) {
        return false;
      }
    }
    return true;
  });
});