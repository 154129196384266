define("ember-file-upload/system/parse-html", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = parseHTML;
  function parseHTML(string) {
    let tmp = document.implementation.createHTMLDocument();
    tmp.body.innerHTML = string;
    return [tmp.body];
  }
});