define('ember-cp-validations/-private/options', ['exports', 'ember-cp-validations/utils/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { keys } = Object;
  const OPTION_KEYS = '__option_keys__';

  const OptionsObject = Ember.Object.extend({
    toObject() {
      return this[OPTION_KEYS].reduce((obj, key) => {
        obj[key] = Ember.get(this, key);
        return obj;
      }, {});
    }
  });

  class Options {
    constructor({ model, attribute, options = {} }) {
      const optionKeys = keys(options);
      const createParams = { [OPTION_KEYS]: optionKeys, model, attribute };

      // If any of the options is a CP, we need to create a custom class for it
      if (optionKeys.some(key => (0, _utils.isDescriptor)(options[key]))) {
        return OptionsObject.extend(options).create(createParams);
      }

      return OptionsObject.create(createParams, options);
    }
  }
  exports.default = Options;
});