define('ember-cp-validations/-private/internal-result-object', ['exports', 'ember-cp-validations/validations/error', 'ember-cp-validations/utils/utils'], function (exports, _error, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    model: null,
    isValid: true,
    isValidating: false,
    message: null,
    warningMessage: null,
    attribute: '',

    _promise: null,
    _validator: null,
    _type: Ember.computed.readOnly('_validator._type'),

    init() {
      this._super(...arguments);

      if (this.get('isAsync')) {
        this._handlePromise();
      }
    },

    isWarning: Ember.computed.readOnly('_validator.isWarning'),
    isInvalid: Ember.computed.not('isValid'),
    isNotValidating: Ember.computed.not('isValidating'),
    isTruelyValid: Ember.computed.and('isNotValidating', 'isValid'),
    isTruelyInvalid: Ember.computed.and('isNotValidating', 'isInvalid'),

    isAsync: Ember.computed('_promise', function () {
      return (0, _utils.isPromise)(Ember.get(this, '_promise'));
    }),

    messages: Ember.computed('message', function () {
      return Ember.makeArray(Ember.get(this, 'message'));
    }),

    error: Ember.computed('isInvalid', 'type', 'message', 'attribute', function () {
      if (Ember.get(this, 'isInvalid')) {
        return _error.default.create({
          type: Ember.get(this, '_type'),
          message: Ember.get(this, 'message'),
          attribute: Ember.get(this, 'attribute')
        });
      }

      return null;
    }),

    errors: Ember.computed('error', function () {
      return Ember.makeArray(Ember.get(this, 'error'));
    }),

    warningMessages: Ember.computed('warningMessage', function () {
      return Ember.makeArray(Ember.get(this, 'warningMessage'));
    }),

    warning: Ember.computed('isWarning', 'type', 'warningMessage', 'attribute', function () {
      if (Ember.get(this, 'isWarning') && !Ember.isNone(Ember.get(this, 'warningMessage'))) {
        return _error.default.create({
          type: Ember.get(this, '_type'),
          message: Ember.get(this, 'warningMessage'),
          attribute: Ember.get(this, 'attribute')
        });
      }

      return null;
    }),

    warnings: Ember.computed('warning', function () {
      return Ember.makeArray(Ember.get(this, 'warning'));
    }),

    _handlePromise() {
      Ember.set(this, 'isValidating', true);

      Ember.get(this, '_promise').finally(() => {
        Ember.set(this, 'isValidating', false);
      });
    }
  });
});