define('ember-cli-g-maps/mixins/g-maps/core/two-way-map-type-control', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { observer } = Ember;

  exports.default = Ember.Mixin.create({

    /**
     * [observer for component attribute's `showMapTypeControl` updates]
     * @param  {Boolean} 'isMapLoaded'
     * @param  {[Boolean]}  'showMapTypeControl'
     * @return {[Boolean]} [returns false if map not updated]
     */
    _bindMapTypeControlToMap: observer('isMapLoaded', 'showMapTypeControl', function () {
      if (!this.get('isMapLoaded')) {
        return false;
      }

      this.get('map.map').setOptions({
        mapTypeControl: this.get('showMapTypeControl') ? true : false
      });
    })
  });
});