define('ember-cp-validations/-private/symbols', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const VALIDATIONS_CLASS = exports.VALIDATIONS_CLASS = '__VALIDATIONS_CLASS__';
  const VALIDATIONS_MIXIN_COUNT = exports.VALIDATIONS_MIXIN_COUNT = '__VALIDATIONS_MIXIN_COUNT__';
  const IS_VALIDATIONS_CLASS = exports.IS_VALIDATIONS_CLASS = '__IS_VALIDATIONS_CLASS__';
  const ATTRS_MODEL = exports.ATTRS_MODEL = '__ATTRS_MODEL__';
  const ATTRS_PATH = exports.ATTRS_PATH = '__ATTRS_PATH__';
  const ATTRS_RESULT_COLLECTION = exports.ATTRS_RESULT_COLLECTION = '__ATTRS_RESULT_COLLECTION__';
});