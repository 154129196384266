define('ember-awesome-macros/promise/hash', ['exports', 'ember-macro-helpers/curried-computed', 'ember-awesome-macros/hash'], function (exports, _curriedComputed, _hash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (...args) {
    let { hashKeys, hashValues } = (0, _hash.deconstructArgs)(args);
    return (0, _curriedComputed.default)((...newValues) => {
      let newHash = (0, _hash.reduceValues)(hashKeys, newValues);
      return hash(newHash);
    })(...hashValues);
  };

  const { hash } = Ember.RSVP;
});