define('ember-cp-validations/utils/array', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.callable = callable;
  exports.flatten = flatten;


  const A = Ember.A();

  function callable(method) {
    return function (collection) {
      return A[method].apply(collection, arguments);
    };
  }

  const uniq = exports.uniq = callable('uniq');
  const compact = exports.compact = callable('compact');

  function flatten(array = []) {
    let result = [];

    for (let i = 0, l = array.length; i < l; i++) {
      let item = array[i];

      if (Array.isArray(item)) {
        result = result.concat(flatten(item));
      } else {
        result.push(item);
      }
    }

    return result;
  }
});