define('ember-infinity/mixins/route', ['exports', 'ember-infinity/lib/infinity-model', 'ember-infinity/lib/infinity-promise-array', 'ember-infinity/mixins/bound-params', 'ember-infinity/utils'], function (exports, _infinityModel, _infinityPromiseArray, _boundParams, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
    The Ember Infinity Route Mixin enables an application route to load paginated
    records for the route `model` as triggered by the controller (or Infinity Loader
    component).
  
    @class RouteMixin
    @namespace EmberInfinity
    @module ember-infinity/mixins/route
    @extends Ember.Mixin
  */
  const RouteMixin = Ember.Mixin.create({

    infinity: Ember.inject.service(),

    _infinityModels: Ember.computed.readOnly('infinity.infinityModels'),

    // these are here for backwards compat
    _infinityModel: Ember.computed('_infinityModels.[]', '_infinityModels', function () {
      return Ember.get(this, '_infinityModels.firstObject');
    }).readOnly(),
    currentPage: Ember.computed.readOnly('_infinityModel.currentPage'),

    actions: {
      /**
        determine if the passed infinityModel already exists on the infinityRoute and
        return boolean to tell infinity-loader component if it should make another request
        @method infinityLoad
        @param {Object} infinityModel
        @param {Integer} increment - to increase page by 1 or -1. Default to increase by one page
        @return {Boolean}
       */
      infinityLoad(infinityModel, increment = 1) {
        let matchingInfinityModel = Ember.get(this, '_infinityModels').find(model => model === infinityModel);
        if (matchingInfinityModel) {
          Ember.set(matchingInfinityModel, '_increment', increment);
          Ember.get(this, 'infinity')['infinityLoad'](matchingInfinityModel, increment);
        } else {
          return true;
        }
      }
    },

    /**
      Use the infinityModel method in the place of `this.store.query('model')` to
      initialize the Infinity Model for your route.
       @method infinityModel
      @param {String} modelName The name of the model.
      @param {Object} options - optional - the perPage and startingPage to load from.
      @param {Object} boundParamsOrInfinityModel - optional -
        params on route to be looked up on every route request or
        instance of InfinityModel
      @return {Ember.RSVP.Promise}
    */
    infinityModel(modelName, options, boundParamsOrInfinityModel) {
      Ember.deprecate('Ember Infinity: Route Mixin will be deprecated in the future. We have implemented a Service-Component interaction as shown in the docs.', false, {
        id: 'ember-infinity',
        until: '1.1.0'
      });

      let boundParams, ExtendedInfinityModel;
      if (Ember.typeOf(boundParamsOrInfinityModel) === "class") {
        if (!(boundParamsOrInfinityModel.prototype instanceof _infinityModel.default)) {
          throw new Ember.Error("Ember Infinity: You must pass an Infinity Model instance as the third argument");
        }
        ExtendedInfinityModel = boundParamsOrInfinityModel;
      } else if (Ember.typeOf(boundParamsOrInfinityModel) === "object") {
        boundParams = boundParamsOrInfinityModel;
      }

      if (modelName === undefined) {
        throw new Ember.Error("Ember Infinity: You must pass a Model Name to infinityModel");
      }

      let service = Ember.get(this, 'infinity');
      if (!Ember.get(service, 'infinityModels')) {
        Ember.set(service, 'infinityModels', Ember.A());
      }

      options = options ? (0, _utils.objectAssign)({}, options) : {};

      if (options.store) {
        Ember.get(this, 'infinity._ensureCustomStoreCompatibility')(options, options.store, options.storeFindMethod || 'query');
      }

      // default is to start at 0, request next page and increment
      const currentPage = options.startingPage === undefined ? 0 : options.startingPage - 1;
      // sets first page when route is loaded
      const firstPage = currentPage === 0 ? 1 : currentPage + 1;
      // chunk requests by indicated perPage param
      const perPage = options.perPage || 25;

      // store service methods (defaults to ember-data if nothing passed)
      const store = options.store || Ember.get(this, 'store');
      const storeFindMethod = options.storeFindMethod || 'query';

      // hook functions
      let infinityModelLoaded, afterInfinityModel;
      if (!ExtendedInfinityModel || ExtendedInfinityModel && !ExtendedInfinityModel.infinityModelLoaded) {
        infinityModelLoaded = Ember.get(this, 'infinityModelLoaded');
      }
      if (!ExtendedInfinityModel || ExtendedInfinityModel && !ExtendedInfinityModel.afterInfinityModel) {
        afterInfinityModel = Ember.get(this, 'afterInfinityModel');
      }

      // check if user passed in param w/ infinityModel, else check if defined on the route (for backwards compat), else default
      const perPageParam = (0, _utils.paramsCheck)(options.perPageParam, Ember.get(this, 'perPageParam'), 'per_page');
      const pageParam = (0, _utils.paramsCheck)(options.pageParam, Ember.get(this, 'pageParam'), 'page');
      const totalPagesParam = (0, _utils.paramsCheck)(options.totalPagesParam, Ember.get(this, 'totalPagesParam'), 'meta.total_pages');
      const countParam = (0, _utils.paramsCheck)(options.countParam, Ember.get(this, 'countParam'), 'meta.count');

      delete options.startingPage;
      delete options.perPage;
      delete options.perPageParam;
      delete options.pageParam;
      delete options.totalPagesParam;
      delete options.countParam;
      delete options.infinityCache;
      delete options.store;
      delete options.storeFindMethod;

      let InfinityModelFactory;
      let didPassBoundParams = !Ember.isEmpty(boundParams);
      if (didPassBoundParams) {
        // if pass boundParamsOrInfinityModel, send to backwards compatible mixin that sets bound params on route
        // and subsequently looked up when user wants to load next page
        InfinityModelFactory = _infinityModel.default.extend(_boundParams.default);
      } else if (ExtendedInfinityModel) {
        // if custom InfinityModel, then use as base for creating an instance
        InfinityModelFactory = ExtendedInfinityModel;
      } else {
        InfinityModelFactory = _infinityModel.default;
      }

      let initParams = {
        currentPage,
        firstPage,
        perPage,
        perPageParam,
        pageParam,
        totalPagesParam,
        countParam,
        _infinityModelName: modelName,
        store,
        storeFindMethod,
        extraParams: options,
        content: Ember.A()
      };

      if (didPassBoundParams) {
        initParams._deprecatedBoundParams = boundParams;
        initParams.route = this;
      }
      if (infinityModelLoaded) {
        initParams.infinityModelLoaded = infinityModelLoaded;
      }
      if (afterInfinityModel) {
        initParams.afterInfinityModel = afterInfinityModel;
      }

      const infinityModel = InfinityModelFactory.create(initParams);
      Ember.get(this, 'infinity._ensureCompatibility')(Ember.get(infinityModel, 'store'), Ember.get(infinityModel, 'storeFindMethod'));
      Ember.get(this, 'infinity.infinityModels').pushObject(infinityModel);

      return _infinityPromiseArray.default.create({ promise: service['loadNextPage'](infinityModel) });
    },

    /**
      Update the infinity model with new objects
      Only called on the second page and following
       @deprecated
      @method updateInfinityModel
      @param {Ember.Enumerable} newObjects The new objects to add to the model
      @return {Ember.Array} returns the new objects
    */
    updateInfinityModel(newObjects) {
      Ember.deprecate('Ember Infinity: this method will be deprecated in the future.', false, {
        id: 'ember-infinity',
        until: '1.1.0'
      });
      return this._doUpdate(newObjects);
    },

    /**
      notify that the infinity model has been updated
       @private
      @method _notifyInfinityModelUpdated
     */
    _notifyInfinityModelUpdated(newObjects) {
      if (!this.infinityModelUpdated) {
        return;
      }

      Ember.deprecate('Ember Infinity: infinityModelUpdated will be deprecated in the future.', false, {
        id: 'ember-infinity',
        until: '1.1.0'
      });
      Ember.run.scheduleOnce('afterRender', this, 'infinityModelUpdated', {
        lastPageLoaded: Ember.get(this, 'currentPage'),
        totalPages: Ember.get(this, '_totalPages'),
        newObjects: newObjects
      });
    }
  });

  exports.default = RouteMixin;
});