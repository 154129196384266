define('ember-awesome-macros/string/-utils', ['exports', 'ember-macro-helpers/curried-computed', 'ember-macro-helpers/lazy-curried-computed'], function (exports, _curriedComputed, _lazyCurriedComputed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.normalizeString = normalizeString;
  exports.normalizeString2 = normalizeString2;
  function normalizeString(func) {
    return (0, _curriedComputed.default)(val => {
      if (!val) {
        return val;
      }

      return func(val);
    });
  }

  function normalizeString2(func, defaultValue = () => {}) {
    return (0, _lazyCurriedComputed.default)((get, stringKey, ...keys) => {
      let stringVal = get(stringKey);
      if (stringVal === undefined) {
        return defaultValue();
      }

      return stringVal[func](...keys.map(get));
    });
  }
});