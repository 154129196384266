define('ember-awesome-macros/promise/-utils', ['exports', 'ember-macro-helpers/curried-computed'], function (exports, _curriedComputed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.wrapPromiseProxy = wrapPromiseProxy;


  const { PromiseProxyMixin } = Ember;
  const { resolve } = Ember.RSVP;

  function wrapPromiseProxy(Proxy) {
    let PromiseProxy = Proxy.extend(PromiseProxyMixin);

    return (0, _curriedComputed.default)(promise => {
      if (promise === undefined) {
        promise = resolve(undefined);
      }

      return PromiseProxy.create({
        promise
      });
    });
  }
});