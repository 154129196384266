define('ember-awesome-macros/promise/then', ['exports', 'ember-macro-helpers/lazy-curried-computed'], function (exports, _lazyCurriedComputed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { resolve } = Ember.RSVP;

  exports.default = (0, _lazyCurriedComputed.default)((getValue, promise, property) => {
    promise = getValue(promise);
    if (promise === undefined) {
      return resolve();
    }
    return promise.then(x => {
      property = getValue(property);
      if (property !== undefined) {
        return Ember.get(x, property);
      }
    });
  });
});