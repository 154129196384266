define('ember-drag-sort/components/drag-sort-item', ['exports', 'ember-macro-helpers/computed', 'ember-awesome-macros/and', 'ember-awesome-macros/not', 'ember-awesome-macros/eq', 'ember-awesome-macros/subtract', 'ember-drag-sort/templates/components/drag-sort-item'], function (exports, _computed, _and, _not, _eq, _subtract, _dragSortItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    // ----- Arguments -----
    item: undefined,
    index: undefined,
    items: undefined,
    group: undefined,
    childTagName: 'div',
    draggingEnabled: undefined,
    handle: null,

    dragEndAction: undefined,
    determineForeignPositionAction: undefined,

    // ----- Services -----
    dragSort: Ember.inject.service(),

    // ----- Overridden properties -----
    layout: _dragSortItem.default,
    classNameBindings: [':dragSortItem', 'isDragged2:-isDragged', 'isDraggingOver:-isDraggingOver', 'shouldShowPlaceholderAbove2:-placeholderAbove', 'shouldShowPlaceholderBelow2:-placeholderBelow'],

    attributeBindings: ['draggable'],

    // ----- Static properties -----
    isDragged2: false,
    originalHeight: null,

    shouldShowPlaceholderAbove2: undefined,
    shouldShowPlaceholderBelow2: undefined,

    // ----- Aliases -----
    isDraggingUp: Ember.computed.reads('dragSort.isDraggingUp'),
    sourceList: Ember.computed.reads('dragSort.sourceList'),
    sourceIndex: Ember.computed.reads('dragSort.sourceIndex'),
    targetIndex: Ember.computed.reads('dragSort.targetIndex'),
    targetList: Ember.computed.reads('dragSort.targetList'),

    // ----- Computed properties -----
    draggable: (0, _computed.default)('draggingEnabled', 'handle', (draggingEnabled, handle) => {
      return !handle && draggingEnabled ? true : null;
    }),

    isDragged: (0, _and.default)('dragSort.isDragging', (0, _eq.default)('items', 'sourceList'), (0, _eq.default)('index', 'sourceIndex')),

    isDraggingOver: (0, _and.default)('dragSort.isDragging', (0, _eq.default)('items', 'targetList'), (0, _eq.default)('index', 'targetIndex'), (0, _not.default)('isDragged')),

    $handle: (0, _computed.default)('handle', function (handleClass) {
      return this.$(handleClass);
    }),

    isLast: (0, _eq.default)('index', (0, _subtract.default)('items.length', 1)),
    shouldShowPlaceholderAbove: (0, _and.default)('isDraggingOver', 'isDraggingUp'),
    shouldShowPlaceholderBelow: (0, _and.default)('isDraggingOver', (0, _not.default)('isDraggingUp')),

    // ----- Overridden methods -----
    dragStart(event) {
      // Ignore irrelevant drags
      if (!this.get('draggingEnabled')) return;

      if (!this.isHandleUsed(event.target)) {
        event.preventDefault();
        return;
      }

      event.stopPropagation();

      // Required for Firefox. http://stackoverflow.com/a/32592759/901944
      if (event.dataTransfer) {
        if (event.dataTransfer.setData) event.dataTransfer.setData('text', 'anything');
        if (event.dataTransfer.setDragImage) event.dataTransfer.setDragImage(this.$().get(0), 0, 0);
      }

      this.startDragging(event);
    },

    dragEnd(event) {
      // Ignore irrelevant drags
      if (!this.get('dragSort.isDragging')) return;

      event.stopPropagation();

      this.endDragging(event);
    },

    dragOver(event) {
      // Ignore irrelevant drags
      if (!this.get('dragSort.isDragging') || this.get('determineForeignPositionAction')) return;

      const group = this.get('group');
      const activeGroup = this.get('dragSort.group');

      if (group !== activeGroup) return;

      event.stopPropagation();

      const pageY = event.originalEvent ? event.originalEvent.pageY : event.pageY;

      this.draggingOver({ pageY });
    },

    dragEnter(event) {
      if (!this.get('dragSort.isDragging')) return;
      // Without this, dragOver would not fire in IE11. http://mereskin.github.io/dnd/
      event.preventDefault();
    },

    // ----- Custom methods -----
    startDragging() {
      this.collapse();

      const item = this.get('item');
      const index = this.get('index');
      const items = this.get('items');
      const group = this.get('group');
      const dragSort = this.get('dragSort');

      dragSort.startDragging({ item, index, items, group });
    },

    endDragging() {
      this.restore();

      const action = this.get('dragEndAction');
      const dragSort = this.get('dragSort');

      dragSort.endDragging({ action });
    },

    draggingOver({ pageY }) {
      const group = this.get('group');
      const index = this.get('index');
      const items = this.get('items');
      const top = this.$().offset().top;
      const height = this.$().outerHeight();
      const isDraggingUp = pageY - top < height / 2;

      this.get('dragSort').draggingOver({ group, index, items, isDraggingUp });
    },

    collapse() {
      // The delay is necessary for HTML classes to update with a delay.
      // Otherwise, dragging is finished immediately.
      Ember.run.next(() => {
        if (this.get('isDestroying') || this.get('isDestroyed')) return;
        this.set('isDragged2', true);
      });
    },

    restore() {
      // The delay is necessary for HTML class to update with a delay.
      // Otherwise, dragging is finished immediately.
      Ember.run.next(() => {
        if (this.get('isDestroying') || this.get('isDestroyed')) return;
        this.set('isDragged2', false);
      });
    },

    isHandleUsed(target) {
      const handle = this.get('handle');

      if (!handle) return true;

      const $target = Ember.$(target);

      if ($target.is(handle)) return true;

      return $target.parentsUntil(this.$()).toArray().some(el => Ember.$(el).is(handle));
    },

    // ----- Observers -----
    consumePlaceholderCPs: Ember.on('didInsertElement', function () {
      this.getProperties('shouldShowPlaceholderAbove', 'shouldShowPlaceholderBelow');
    }),

    setPlaceholderAbove: Ember.observer('shouldShowPlaceholderAbove', function () {
      // The delay is necessary for HTML class to update with a delay.
      // Otherwise, dragging is finished immediately.
      Ember.run.next(() => {
        if (this.get('isDestroying') || this.get('isDestroyed')) return;
        this.set('shouldShowPlaceholderAbove2', this.get('shouldShowPlaceholderAbove'));
      });
    }),

    setPlaceholderBelow: Ember.observer('shouldShowPlaceholderBelow', function () {
      // The delay is necessary for HTML class to update with a delay.
      // Otherwise, dragging is finished immediately.
      Ember.run.next(() => {
        if (this.get('isDestroying') || this.get('isDestroyed')) return;
        this.set('shouldShowPlaceholderBelow2', this.get('shouldShowPlaceholderBelow'));
      });
    })
  });
});