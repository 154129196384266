define('ember-cropperjs/components/image-cropper-on', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const ImageCropperOnComponent = Ember.Component.extend({
    tagName: '',

    cropper: null,

    eventSource: null,
    event: null,
    action: null,

    _prevEvent: null,

    init() {
      this._super(...arguments);

      this._boundOnEvent = Ember.run.bind(this, this._onEvent);
    },

    _normalizedEvent: Ember.computed('event', function () {
      const event = Ember.get(this, 'event');
      (true && !(typeof event === 'string') && Ember.assert(`image-cropper-event requires event to be a string, was ${event}`, typeof event === 'string'));


      return event.toLowerCase();
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      const { eventSource, _normalizedEvent, _prevEvent, action } = Ember.getProperties(this, 'eventSource', '_normalizedEvent', '_prevEvent', 'action');

      (true && !(Ember.isPresent(eventSource)) && Ember.assert('image-cropper-event requires an eventSource', Ember.isPresent(eventSource)));
      (true && !(Ember.isPresent(action)) && Ember.assert('image-cropper-event requires an action', Ember.isPresent(action)));


      if (_normalizedEvent !== _prevEvent) {
        if (_prevEvent) {
          eventSource.removeEventListener(_prevEvent, this._boundOnEvent);
        }

        this._prevEvent = _normalizedEvent;
        eventSource.addEventListener(_normalizedEvent, this._boundOnEvent);
      }
    },

    willDestroy() {
      this._super(...arguments);

      const { eventSource, _prevEvent } = Ember.getProperties(this, 'eventSource', '_prevEvent');
      if (eventSource && _prevEvent) {
        eventSource.removeEventListener(_prevEvent, this._boundOnEvent);
      }
    },

    _onEvent() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      const { action } = this;

      if (action) {
        action(this.cropper, ...arguments);
      }
    }
  });

  ImageCropperOnComponent.reopenClass({
    positionalParams: ['event', 'action']
  });

  exports.default = ImageCropperOnComponent;
});