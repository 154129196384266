define('ember-infinity/mixins/bound-params', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    route: null,
    _deprecatedBoundParams: null,

    /**
      get bound param off of route and include in params
       @method buildParams
      @return {Object}
     */
    buildParams() {
      Ember.deprecate("Ember Infinity: Bound params are now deprecated. Please pass explicitly as second param to the infinityModel method", false, {
        id: 'ember-infinity',
        until: '1.0.0'
      });

      let params = this._super(...arguments);
      let boundParams = Ember.get(this, '_deprecatedBoundParams');

      if (!Ember.isEmpty(boundParams)) {
        Object.keys(boundParams).forEach(k => params[k] = Ember.get(this, `route.${boundParams[k]}`));
      }

      return params;
    }
  });
});