define('ember-infinity/components/infinity-loader', ['exports', 'ember-infinity/lib/infinity-promise-array', 'ember-in-viewport'], function (exports, _infinityPromiseArray, _emberInViewport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const InfinityLoaderComponent = Ember.Component.extend(_emberInViewport.default, {
    infinity: Ember.inject.service(),

    classNames: ['infinity-loader'],
    classNameBindings: ['infinityModelContent.reachedInfinity', 'viewportEntered:in-viewport'],
    /**
     * @public
     * @property eventDebounce
     * @default 50
     */
    eventDebounce: 50,
    /**
     * @public
     * @property loadingText
     */
    loadingText: 'Loading Infinite Model...',
    /**
     * @public
     * @property loadedText
     */
    loadedText: 'Infinite Model Entirely Loaded.',
    /**
     * @public
     * @property hideOnInfinity
     * @default false
     */
    hideOnInfinity: false,
    /**
     * @public
     * @property developmentMode
     * @default false
     */
    developmentMode: false,
    /**
     * indicate to infinity-loader to load previous page
     *
     * @public
     * @property loadPrevious
     * @default false
     */
    loadPrevious: false,
    /**
     * set if have scrollable area
     *
     * @public
     * @property scrollable
     */
    scrollable: null,
    /**
     * offset from bottom of target and viewport
     *
     * @public
     * @property triggerOffset
     * @defaul 0
     */
    triggerOffset: 0,
    /**
     * https://emberjs.com/api/ember/3.0/classes/Component/properties/isVisible?anchor=isVisible
     *
     * @property isVisible
     */
    isVisible: true,

    willInsertElement() {
      if (Ember.get(this, '_isInfinityPromiseArray')) {
        Ember.defineProperty(this, 'infinityModelContent', Ember.computed.alias('infinityModel.promise'));
      } else {
        Ember.defineProperty(this, 'infinityModelContent', Ember.computed.alias('infinityModel'));
      }
    },

    /**
     * setup ember-in-viewport properties
     *
     * @method didInsertElement
     */
    didInsertElement() {
      this._super(...arguments);

      let scrollableArea = Ember.get(this, 'scrollable');

      this.setProperties({
        viewportSpy: true,
        viewportTolerance: {
          top: 0,
          right: 0,
          bottom: Ember.get(this, 'triggerOffset'),
          left: 0
        },
        scrollableArea
      });
      let infinityModel = Ember.get(this, 'infinityModelContent');
      if (infinityModel) {
        Ember.set(infinityModel, '_scrollable', scrollableArea);
      }
    },

    willDestroyElement() {
      this._super(...arguments);
      this._cancelTimers();
    },

    _isInfinityPromiseArray: Ember.computed('infinityModel', function () {
      return Ember.get(this, 'infinityModel') instanceof _infinityPromiseArray.default;
    }),

    /**
     * https://github.com/DockYard/ember-in-viewport#didenterviewport-didexitviewport
     *
     * @method didEnterViewport
     */
    didEnterViewport() {
      if (Ember.get(this, 'developmentMode') || typeof FastBoot !== 'undefined' || this.isDestroying || this.isDestroyed) {
        return false;
      }

      if (Ember.get(this, 'loadPrevious')) {
        return this._debounceScrolledToTop();
      }
      return this._debounceScrolledToBottom();
    },

    /**
     * https://github.com/DockYard/ember-in-viewport#didenterviewport-didexitviewport
     *
     * @method didExitViewport
     */
    didExitViewport() {
      this._cancelTimers();
    },

    /**
     * @method loadedStatusDidChange
     */
    loadedStatusDidChange: Ember.observer('infinityModelContent.reachedInfinity', 'hideOnInfinity', function () {
      if (Ember.get(this, 'infinityModelContent.reachedInfinity') && Ember.get(this, 'hideOnInfinity')) {
        Ember.set(this, 'isVisible', false);
      }
    }),

    /**
     * only load previous page if route started on a page greater than 1 && currentPage is > 0
     *
     * @method _debounceScrolledToTop
     */
    _debounceScrolledToTop() {
      /*
       This debounce is needed when there is not enough delay between onScrolledToBottom calls.
       Without this debounce, all rows will be rendered causing immense performance problems
       */
      const infinityModelContent = Ember.get(this, 'infinityModelContent');

      function loadPreviousPage() {
        if (typeof Ember.get(this, 'infinityLoad') === 'function') {
          // closure action
          return Ember.get(this, 'infinityLoad')(infinityModelContent, -1);
        } else {
          Ember.get(this, 'infinity').infinityLoad(infinityModelContent, -1);
        }
      }

      if (Ember.get(infinityModelContent, 'firstPage') > 1 && Ember.get(infinityModelContent, 'currentPage') > 0) {
        this._debounceTimer = Ember.run.debounce(this, loadPreviousPage, Ember.get(this, 'eventDebounce'));
      }
    },

    /**
     * @method _debounceScrolledToBottom
     */
    _debounceScrolledToBottom() {
      /*
       This debounce is needed when there is not enough delay between onScrolledToBottom calls.
       Without this debounce, all rows will be rendered causing immense performance problems
       */
      function loadMore() {
        // resolve to create thennable
        // type is <InfinityModel|Promise|null>
        let infinityModelContent = Ember.RSVP.resolve(Ember.get(this, 'infinityModelContent'));

        infinityModelContent.then(content => {
          if (typeof Ember.get(this, 'infinityLoad') === 'function') {
            // closure action (if you need to perform some other logic)
            return Ember.get(this, 'infinityLoad')(content);
          } else {
            // service action
            Ember.get(this, 'infinity').infinityLoad(content, 1).then(() => {
              if (Ember.get(content, '_canLoadMore')) {
                this._checkScrollableHeight();
              }
            });
          }
        });
      }
      this._debounceTimer = Ember.run.debounce(this, loadMore, Ember.get(this, 'eventDebounce'));
    },

    /**
     * recursive function to fill page with records
     *
     * @method _checkScrollableHeight
     */
    _checkScrollableHeight() {
      if (this._viewportHeight() > this.element.offsetTop) {
        // load again
        this._debounceScrolledToBottom();
      }
    },

    _cancelTimers() {
      Ember.run.cancel(this._debounceTimer);
    },

    /**
      calculate the height of the viewport
       @private
      @method _viewportHeight
      @return Integer
     */
    _viewportHeight() {
      if (typeof FastBoot === 'undefined') {
        let isScrollable = !!this.scrollable;
        let viewportElem = isScrollable ? document.querySelector(this.scrollable) : window;
        return isScrollable ? viewportElem.clientHeight : viewportElem.innerHeight;
      }
    }
  });

  exports.default = InfinityLoaderComponent;
});