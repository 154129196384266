define('ember-awesome-macros/hash', ['exports', 'ember-macro-helpers/curried-computed'], function (exports, _curriedComputed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.deconstructArgs = deconstructArgs;
  exports.reduceValues = reduceValues;

  exports.default = function (...args) {
    let { hashKeys, hashValues } = deconstructArgs(args);
    return (0, _curriedComputed.default)((...newValues) => {
      let newHash = reduceValues(hashKeys, newValues);
      return Ember.Object.create(newHash);
    })(...hashValues);
  };

  function deconstructArgs(args) {
    let hashKeys = [];
    let hashValues = [];

    args.forEach(arg => {
      if (typeof arg === 'string') {
        hashKeys.push(arg);
        hashValues.push(arg);
      } else {
        let props = Object.getOwnPropertyNames(arg);
        hashKeys = hashKeys.concat(props);
        hashValues = hashValues.concat(props.map(prop => arg[prop]));
      }
    });

    return {
      hashKeys,
      hashValues
    };
  }

  function reduceValues(hashKeys, newValues) {
    return newValues.reduce((newHash, val, i) => {
      newHash[hashKeys[i]] = val;
      return newHash;
    }, {});
  }
});