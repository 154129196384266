define('ember-cropperjs/components/image-cropper', ['exports', 'ember-cropperjs/templates/components/image-cropper', 'cropperjs'], function (exports, _imageCropper, _cropperjs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Properties that do not require a new Cropper instance, rather just need to call
  // a method on the existing instance
  const OPT_UPDATE_METHODS = {
    'aspectRatio': 'setAspectRatio'
  };

  exports.default = Ember.Component.extend({
    classNames: ['image-cropper'],
    layout: _imageCropper.default,

    alt: '',
    source: null,
    options: null,

    _cropper: null,
    _prevOptions: null,
    _prevSource: null,

    didInsertElement() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', this, this._setup);
    },

    didUpdateAttrs() {
      this._super(...arguments);

      const _cropper = Ember.get(this, '_cropper');

      if (_cropper === null) {
        return;
      }

      // Check if the image source changed
      if (Ember.compare(Ember.get(this, 'source'), Ember.get(this, '_prevSource')) !== 0) {
        const source = Ember.get(this, 'source');

        _cropper.replace(source);
        Ember.set(this, '_prevSource', source);
      }

      const options = Ember.get(this, 'options');

      if (options) {
        // Diff the `options` hash for changes
        for (let i = 0; i < Object.keys(OPT_UPDATE_METHODS).length; i++) {
          const opt = Object.keys(OPT_UPDATE_METHODS)[i];

          if (Ember.compare(options[opt], this._prevOptions[opt]) !== 0) {
            _cropper[OPT_UPDATE_METHODS[opt]](options[opt]);
          }
        }

        Ember.set(this, '_prevOptions', Ember.copy(options));
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      const _cropper = Ember.get(this, '_cropper');
      if (_cropper !== null) {
        _cropper.destroy();
      }
    },

    _setup() {
      const image = document.getElementById(`image-cropper-${Ember.get(this, 'elementId')}`);
      const options = Ember.get(this, 'options');

      // Need a copy because Cropper does not seem to like the Ember EmptyObject that is created from the `{{hash}}` helper
      const opts = Ember.copy(options);

      Ember.setProperties(this, {
        _cropper: new _cropperjs.default(image, opts),
        _prevOptions: Ember.copy(Ember.get(this, 'options')),
        _prevSource: Ember.copy(Ember.get(this, 'source'))
      });
    }
  });
});